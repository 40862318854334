import { Component, OnInit } from '@angular/core';
import { _require } from '../providers/require_lib';
import { HttpService } from "../providers/HttpService"
import { Router } from "@angular/router";
import { Utils } from '../providers/Utils'
import { NativeService } from '../providers/NativeService';

@Component({
  selector: 'app-invite-friends',
  templateUrl: './invite-friends.component.html',
  styleUrls: ['./invite-friends.component.css']
})
export class InviteFriendsComponent implements OnInit {

  public info:any = {
    bgimg: '',
    codeimg: '',
    downimg: ''
  };
  constructor(public http: HttpService, public router: Router, public utils: Utils, public nativeService: NativeService) { }

  ngOnInit() {
    this.getInfo();
  }

  public backToPage() {
    this.nativeService.backToPage();
  }

  public getInfo() {
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.vip.MemberManage.shareInfo',
      'plat': 'plat_wap',
      'openno': '2',
      'shopid': this.utils.getLocalStorageItem('shopid')
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      if (result.code == 100 && result.data) {
        this.info = result.data;
      } else {
        this.nativeService.showWarningAlert(result.msg);
        if (result.msg == "登录已过期,请重新登录" || result.msg == "请登录") {
          this.utils.setLocalStorageItem("isLogin", false);
        }
      }
    })
  }

  public saveImg(){
    console.log(1)
    let url = this.info.downimg;
    this.downloadIamge(url,'分享vip')
  }

  public downloadIamge(imgsrc, name) { // 下载图片地址和图片名
    console.log(2)
    var image = new Image()
    // 解决跨域 Canvas 污染问题
    image.setAttribute('crossOrigin', 'anonymous')
    image.onload = function () {
      var canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height
      var context = canvas.getContext('2d')
      context.drawImage(image, 0, 0, image.width, image.height)
      var url = canvas.toDataURL('image/png') // 得到图片的base64编码数据
      var a = document.createElement('a') // 生成一个a元素
      var event = new MouseEvent('click') // 创建一个单击事件
      a.download = name || 'photo' // 设置图片名称
      a.href = url // 将生成的URL设置为a.href属性
      a.dispatchEvent(event) // 触发a的单击事件
    }
    image.src = imgsrc
  }

}
